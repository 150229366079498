.lesson-style-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 27px 20px;
}

.lesson-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 27px 20px;

  .lesson-label {
    font-size: 22px;
    font-weight: 600;
    color: #101828;
  }
  .lessonStyleFormBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}
