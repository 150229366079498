.header-container {
  justify-content: space-between;
  font-weight: 500;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  padding: 19px;
  position: fixed;
  width: 82%;
  background-color: white;
  z-index: 7;
  .header-name {
    color: #1f2937;
    font-size: 24px;
    font-weight: 700;
    padding: 0px !important;
    line-height: 20px;
  }
}
.right-header {
  background-color: transparent;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.header-icon {
  border: 1px solid #e5e7eb;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  margin-right: 15px;
}
.pointer {
  cursor: pointer;
}
.profile_dropdown{
  width: fit-content !important;
  top:50px !important;
}
.user-profile-image{
  border-radius: 50%;
}
