.user-profile-image {
  display: flex;
  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .upload-file-wrapper {
    gap: 5px;
    align-items: center;
    justify-content: start;
    .img-actions-wrapper {
      flex: none;
    }
    .preview-container{
      flex: none;
    }
  }
  .user-profile-wrapper {
    width: 100%;
  }
}
