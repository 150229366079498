.student-notification-wrapper {
  .dashboardNotifications-wrapper {
    border: none;
  }
  .recentNotify-heading,
  .viewAll-text,
  .notifyTime-info {
    color: #fff;
  }
}
.student-notification {
  background: #3f4dc3;
  border: none !important;
  margin: 15px 0px;
  border-radius: 10px;
  padding: 20px;
  align-items: center;
  &:hover {
    background: #3f4dc3 !important;
  }

  .notify-popup-title {
    font-size: 16px;
    color: #fff;
  }
  .notification-time {
    font-size: 14px;
    color: #fff;
    line-height: normal;
  }
  .notification-desc {
    display: none;
  }
  .btn-inner {
    padding: 0px 12px;
    .btn-text {
      font-size: 16px;
    }
  }
}

.student-tabs {
  .MuiButtonBase-root {
    color: #87a5f2;
    &.Mui-selected {
      color: #fff !important;
    }
  }
  .MuiTabs-indicator {
    background-color: #fff !important;
  }
  .dashboardNotifications-wrapper{
    border: none;
  }
  .notify-popup-title{
    display: block;
  }
  .announcement-width{
    .img-message{
      max-width: 100%;
    }
  }
}
