.holdon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;

  .holdon-text-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
}

.holdit-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: center;
  padding: 0px 35px;

  .holdit-text {
    font-size: 32px;
    line-height: 48px;
    font-weight: 900;
    color: #ffffff;
    text-align: center;
  }

  .holdit-subtext {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
  }
}

.holdon-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
}

.MuiDialogActions-root {
  width: 89%;
  justify-content: center;
  position: absolute;
  position: fixed;
  bottom: 2%;
  align-items: center;
  @media only screen and (max-width: 950px) {
    bottom: -2%;
    width: 88%;
  }
}
