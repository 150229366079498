.welcome-text {
    font-size: 28px;
    line-height: 44px;
    margin-bottom: 25px;
    @media only screen and (max-width:1024px){
      font-size: 14px;
      line-height: 17px;
      
    }
  }
  .student-avatar-wrapper {
    .btn-text{
      @media  only screen and (max-width:1024px){
        font-size: 16px;
      }
    }
  }