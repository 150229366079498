.questions-listDetail-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 27px 20px;
  align-items: center;
  .questions-headingLabel {
    font-size: 22px;
    font-weight: 600;
    color: #101828;
  }
}

.moduleName-quesList {
  cursor: pointer;
}

.quesAnsInfo-listWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.askAndPinQuesWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;

  .askWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
  }

  .pinWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
  }

  .quesPinAndAskLabel {
    font-size: 12px;
    font-weight: 400;
    color: #6b7280;
  }

  .quesPinAndAskText {
    font-size: 12px;
    font-weight: 500;
    color: #0040F0;
  }

  .quesPinAndAskText::first-letter {
    text-transform: uppercase;
  }
}
