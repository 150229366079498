.studentQuesAns-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 10px;
}

.quesAns-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .quesAns {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
  }

  .ques-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;

    .quesText {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #1f2937;
    }
  }

  .ans-wrapper {
    padding: 0px 0px 0px 19px;

    .answerText {
      font-size: 14px;
      line-height: 20px;
      color: #374151;
      font-weight: 500;
    }
  }
}

.pinnedDetails-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  gap: 4px;

  .pin-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .pinByText {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #374151;
    }

    .pinByPerson {
      border-radius: 12px;
      padding: 0px 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14.52px;
      color: #0040F0;
      text-transform: capitalize;
    }

    .pinDot {
      width: 4px;
      height: 4px;
      color: #374151;
    }

    .pinMonths {
      border-radius: 12px;
      padding: 0px 4px;

      .pinLessonText {
        font-weight: 600;
        font-size: 12px;
        line-height: 14.52px;
        color: #0040F0;
      }

      .pinMonthsText {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #374151;
      }
    }
  }
}

.deleteQues-icon {
  cursor: pointer;
}

.answerNotAvailText {
  font-weight: 400;
  font-size: 12px;
  color: #1f2937;
  opacity: 0.5;
}
