.text-editor-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.editor-label-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .editor-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #374151;
  }
  .trash-icon {
    cursor: pointer;
  }
}

.demo-editor {
  border: 1.5px solid #e5e7eb;
  border-radius: 12px;
  padding: 12px 16px;
}

.demo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 0 0px;
}

.demo-wrapper .rdw-editor-toolbar {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.rdw-link-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

.rdw-link-wrapper .rdw-link-modal {
  position: absolute;
  top: 25px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 220px;
  border: 1.5px solid #e5e7eb;
  padding: 10px;
  border-radius: 12px;
  z-index: 100;
  background: white;
  gap: 0px;
}

.rdw-link-modal {
  .rdw-link-modal-label {
    font-size: 14px;
    font-weight: 400;
    color: #374151;
  }
  .rdw-link-modal-input {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #e5e7eb;
  }

  .rdw-link-modal-target-option {
    display: flex;
    gap: 5px;
  }

  .rdw-link-modal-buttonsection {
    display: flex;
    gap: 5px;
    justify-content: center;

    .rdw-link-modal-btn {
      font-size: 14px;
      border: 1px solid #e5e7eb;
      padding: 4px 8px;
      background-color: white;
      border-radius: 8px;
      color: #374151;
    }
  }
}

.toolbar-class {
  border: 1.5px solid #e5e7eb;
  border-radius: 12px;
}
