.notification-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  transition: background-color 0.3s;
  padding: 16px;
  // height: 69px;
  justify-content: space-between;
}

.notification-item:hover {
  background-color: #ebf5ff;
  cursor: pointer;
}

.notify-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  // max-width: 70%;
  flex-grow: 1;
}

.notify-popup-title {
  font-size: 14px;
  color: #1f2937;
  font-weight: 600;
  line-height: 16.94px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}

.notification-desc {
  font-size: 14px;
  color: #4b5563;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.notification-time {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #9ca3af;
}
.img-message{
  display: flex;
  gap:16px;
  width: fit-content;
  max-width: 75%;
}
