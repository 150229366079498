.help-modal-wrapper {
  display: flex;
  flex-wrap: wrap;
  .help-card-inner {
    position: relative;
    img {
      right: 15px;
      bottom: 0;
      width: 17px;
      position: absolute;
      top: 0;
      margin: auto;
      cursor: pointer;
      transform: rotate(180deg);
    }
    .heading {
      cursor: pointer;
    }
  }
}
.help-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 17px;
  }
  .help-header {
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
  }
  .back-icon {
    position: absolute;
    left: 10px;
    cursor: pointer;
  }
}
img.back-icon{
  width: 17px;
}
.section-help-pdf{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 40vh;
}
