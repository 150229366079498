.login-wrapper {
  flex: 1;
  justify-content: center;
  padding: 16px;
  align-items: center;
  background: url(../../../public/images/loginbg.png);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100%;
  // @media only screen and (max-width: 1024px) {
  //   background: url(../../../public/images/login-screen-tablet.png);
  // }
  .login-container {
    max-width: 488px;
    margin: auto !important;
    padding: 40px !important;
    background: #fff;
    border-radius: 24px;
  }
  a {
    padding-top: 10px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
  }
  h3 {
    font-size: 32px;
    font-weight: 700;
    color: var(--black1);
    margin: 0;
    font-family: var(--font-inter-semibold);
  }
  .subtitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--black2);
    font-family: var(--font-inter-bold);
  }
  .back-btn {
    cursor: pointer;
  }
  .center{
    text-align: center;
  }
  .block{
    display: block;
  }
  input {
    height: 48px;
    border-color: var(--grey-1);
    border-width: 1.5px !important;
    padding-bottom: 25px;
    padding-left: 12px !important;
    border-radius: 12px !important;
    color: var(--grey3);
    font-size: 16px;
  }
}

.button-text {
  color: white;
}
.password-input {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input .password-toggle {
  
  position: absolute;
  right: 10px;
  cursor: pointer;
  svg{
    fill:#9CA3AF;
  }
}
.input-error {
  border-color:#EF4444;
  border-width: 1.5px;
}
.error{
  color:#EF4444;
  font-size: 12px;
  padding-bottom: 20px;
  padding-left: 15px;
}