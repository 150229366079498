.progress-statusView-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.progressStatus-topSectionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .cus-select {
    border: none;
    padding: 0px;

    select {
      font-weight: 600;
      line-height: 21px;
      font-size: 13px;
      color: #18181b;
    }
  }
}
