.token-progress-inner {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  height: 29px;
  position: relative;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  border: 3px solid #000;
  border-bottom:6px solid #000;
  border-radius: 48px;
}
.token-progress-bar {
  overflow: hidden;
  height: 20px;
}

.token-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.token-progress-bar-s {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.progress-gradient {
    width: 100%;
    height: 8.72px;
    top: 3.64px;
    // left: 211.82px;
    gap: 0px;
    position: absolute;
    border-radius: 55px 0px 0px 0px;
    opacity: 0px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.196));
}
