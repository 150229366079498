.tabs-filter-wrapper {
  justify-content: space-between;
  margin: 25px 20px;
  align-items: center;
  .tabs-wrapper {
    align-items: center;
    border-radius: 12px;
    border: 1.5px solid #e5e7eb;
    height: 44px;
    .tab-style {
      color: #4b5563;
      font-size: 14px;
      font-weight: 600;
      font-family: var(--font-inter-semibold);
      cursor: pointer;
      border-left: 1.5px solid #e5e7eb;
      padding: 0px 18px 0px 17px;
      height: 41px;
      line-height: 41px;
      margin: auto;
      &:first-child {
        border: none;
      }
    }
  }

  .tab-style-selected {
    background-color: #e5e7eb;
    padding: 11px 14px;
    color: #4b5563;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-inter-semibold);
    &:first-child {
      border-radius: 12px 0px 0px 12px;
    }
    &:last-child {
      border-radius: 0px 12px 12px 0px;
    }
  }

  .tabsSearchbar-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}
