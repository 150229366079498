.actions-wrapper {
  .lesson-draft {
    text-transform: capitalize;
    padding: 4px 12px;
    color: #f09d00;
    background: #fefaf6;
    border-radius: 16px;
  }
  .lesson-published {
    text-transform: capitalize;
    padding: 4px 12px;
    color: #02ae85;
    background: #f5fffd;
    border-radius: 16px;
  }
  .quickActions-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.lesson_name_desc-enable {
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
  .l_name {
    font-size: 14px;
    font-weight: 500;
    color: #111827;
  }
  .l-shortDesc {
    font-size: 14px;
    font-weight: 400;
    color: #6b7280;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 340px;
  }
}

.module-list-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 27px 20px;
  align-items: center;
  .module-label {
    font-size: 22px;
    font-weight: 600;
    color: #101828;
    text-transform: capitalize;
  }
}

.lesson_name_desc-disable {
  text-align: left;
  text-transform: capitalize;
  .l_name {
    font-size: 14px;
    font-weight: 500;
    color: #111827;
  }
  .l-shortDesc {
    font-size: 14px;
    font-weight: 400;
    color: #6b7280;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 340px;
  }
}
