.student-tropical-section-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
}

.student-section-container {
  height: 100vh;
}
.play-buttons {
  display: flex;
  bottom: 39%;
  .play-button {
    > img {
      // height: 8%;
      position: absolute;
    }
    .islandIcon {
      margin: auto;
      position: absolute;
    }
    &.tidal_pool {
      > img,
      .islandIcon {
        left: 5%;
        top: 65%;
      }
    }
    &.coconut_tree {
      > img,
      .islandIcon {
        top: 44%;
        left: 15%;
      }
    }
    &.tiki_snack {
      img,
      .islandIcon {
        top: 55%;
        left: 29%;
      }
    }
    &.beach_blanket {
      img,
      .islandIcon {
        top: 66%;
        left: 49%;
      }
    }
    &.clam_music {
      img,
      .islandIcon {
        top: 56%;
        left: 76%;
      }
    }
    &.beach_surfboard {
      img,
      .islandIcon {
        right: 4%;
        top: 64%;
      }
    }
  }
}

.islandHelp {
  left: 20%;
  position: fixed;
  z-index: 43;
  bottom: 4%;
  width: 29%;
}

.student-tropical-section-wrapper {
  position: relative;
}

.scroll-container {
  overflow-x: scroll;
  // white-space: nowrap;
}
