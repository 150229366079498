.lesson-complete-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;

  .lesson-complete-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    .lesson-complete-icon {
      width: 80px;
      height: 80px;
    }

    .lesson-complete-text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .lesson-complete-text {
        font-weight: 900;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
      }

      .lesson-complete-subtext {
        font-weight: 600;
        font-size: 16px;
        line-height: 19.36px;
        color: #ffffff;
      }
    }

    .lesson-time-taken-container {
      border-radius: 16px;
      padding: 20px;
      border: 1px solid #aab5ff;
      display: flex;
      align-items: center;
      gap: 16px;

      .lesson-timer-icon {
        width: 32px;
        height: 32px;
      }

      .lesson-time-taken {
        font-weight: 600;
        font-size: 24px;
        line-height: 29.05px;
        color: #ffffff;
      }
      .time-taken-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: #d0d8ff;
      }
    }
  }
}

.lesson-complete-header {
  display: flex;
  justify-content: flex-end;
}

.complete-close-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
