.text-and-images-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text-label {
  font-size: 20px;
  font-weight: 700;
  color: #1f2937;
  line-height: 48px;
}

.images-container {
  width: 100%;
}

.image-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.content-label-wrapper {
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex: 1;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.item-text {
  font-size: 16px;
  color: #1f2937;
  font-weight: 600;
}

.add-content-icon {
  width: 24px;
  height: 24px;
}

.add-content-actions-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .buttons {
    width: 100%;
  }
}

.add-content-button-wrapper {
  padding: 24px;
  position: fixed;
  margin-bottom: 15%;
  overflow: auto;
  height: calc(100% - 20%);
  border: 1px solid #e5e7eb;
  border-radius: 24px;
  width: -webkit-fill-available;
  margin-right: 20px;
}

// Drag and Drop
.handle-icon {
  font-size: 20px;
  cursor: grab;
}

.handle-icon:active {
  cursor: grabbing;
}
