.details-cardView {
  padding: 10px;
  background-color: #ffffff;
  border: 1.5px solid #e5e7eb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;

  .details-cardTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #18181b;
  }

  .cardIcon-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .details-cardCount {
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    color: #18181b;
  }

  .details-cardIcon {
    width: 32px;
    height: 32px;
  }
}
