.dashboard {
  display: flex;
  margin: 27px 20px;
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .left-column {
    width: 65%;
    @media (max-width: 768px) {
      width: 100%;
    }

    .cards-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 24px;
      margin-bottom: 24px;
    }

    .studentDetails-headingWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .studentDetails-headingLabel {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: #101828;
      }
    }
  }

  .right-column {
    width: 35%;
    padding: 12px 16px;
    border-radius: 12px;
    border: 1.5px solid #e5e7eb;
    background-color: #ffffff;
    overflow-y: auto;
    max-height: 100vh;
    @media (max-width: 768px) {
      width: 100%;
      max-height: none;
    }
  }
}

.dashboard-tableContainer {
  margin: 0px !important;
}

.student_nameWrapper {
  text-align: left;
  .s_name {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #111827;
    text-transform: capitalize;
  }
  .s_email {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6b7280;
  }
  .s_logo {
    img {
      margin-right: 10px;
      object-fit: cover;
      height: 40px;
      border: 1px solid #e5e7eb;
      border-radius: 50%;
      width: 40px;
    }
  }
}
