.student-avatar-display {
  border-radius: 10px;
  background-color: #3f4dc3;
  padding: 20px;
  text-align: center;
  position: relative;
  img {
    height: 120px;
  }
}
.student-form {
  border-radius: 10px;
  background-color: #3f4dc3;
}
.profile-avatar {
  background: #3f4dc3 !important;
  border-radius: 10px;
}
.student-avatar-display-inner {
  background: radial-gradient(circle, rgb(0, 109, 189) 29%, rgb(24, 5, 66) 100%),
    rgb(57, 16, 161) 100%;
  padding: 40px 20px;
  border-radius: 10px;
  .edit {
    height: 40px;
    width: 40px;
    top: 32px;
    position: absolute;
    right: 27px;
    cursor: pointer;
  }
}
.student-form {
  label {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    @media only screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }
  .cus-student-label {
    input {
      background: transparent;
      border: none;
      font-size: 18px;
      color: #fff;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
      }
      &:focus {
        background: transparent;
        border: none;
      }
    }
    &.editable {
      input {
        &:hover,
        &:focus {
          background: #fff;
          color: #000;
        }
      }
    }
  }
}
