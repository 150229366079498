.studentIslandContainer {
  display: flex;
  flex-wrap: nowrap !important;
  .studentIslandCardDetailsWrapper {
    margin-top: 4%;
    margin-bottom: 4%;
    @media only screen and (max-width: 900px) {
      margin-top: 1%;
      margin-bottom: 3%;
    }
    &.even {
      transform: translate(0%, 25%);
    }
    &.odd {
      transform: translate(0%, -25%);
      &.island1 {
        &:after {
          content: '';
          background: url('../../../public/images/stairs2.svg') no-repeat center
            center fixed;
          width: 100%;
          height: 100%;
          -webkit-background-size: 100% 100vh;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: 56%;
          display: block;
          transform: skew(9deg) translate(59%, -71%);
        }
      }
    }
    &.island2 {
      &:after {
        content: '';
        background: url('../../../public/images/stairs1.svg') no-repeat center
          center fixed;
        width: 100%;
        height: 100%;
        -webkit-background-size: 100% 100vh;
        -moz-background-size: cover;
        -o-background-size: cover;
        display: block;
        background-size: 54%;
        transform: skew(-16deg) translate(24%, -105%);
      }
    }
    &.island3 {
      &:after {
        content: '';
        background: url('../../../public/images/stairs2.svg') no-repeat center
          center fixed;
        width: 100%;
        height: 100%;
        -webkit-background-size: 100% 100vh;
        -moz-background-size: cover;
        -o-background-size: cover;
        display: block;
        background-size: 60%;
        transform: skew(1deg) translate(46%, -53%);
      }
    }

    .detailsContainer {
      position: relative;
      z-index: 9;
      .detailsWrapper {
        position: relative;
        width: fit-content;
        margin: auto;
        .fix-frame {
          width: 92%;
          @media only screen and (max-width: 1200px) {
            width: 84%;
          }
          @media only screen and (max-width: 900px) {
            width: 57%;
            margin: auto;
            display: block;
          }
        }

        .islandIcon {
          margin: auto;
          bottom: 0;
          position: absolute;
          top: 0px;
          left: 0;
          right: 0;
          transform: translate(0%, -61%);
          &.active-play-button{
            transform: translate(0%, 11%);
          }
          @media only screen and (max-width: 1024px) {
            transform: translate(0%, -78%);
          }
        }
      }
    }
  }
}

.studentIslandCardDetailsWrapper {
  width: 100%;
}
