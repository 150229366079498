.notifyTime-info {
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
  color: #111827;
  width: 100%;
}

.dashboardNotifications-wrapper {
  border: 1px solid #f3f4f6;
  border-radius: 8px;
  margin: 12px 0px;
  .notification-item:not(:last-child) {
    border-bottom: 1px solid #f3f4f6;
  }
}
