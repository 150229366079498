.help-card-wrapper {
  display: flex;
  .help-card-inner {
    padding: 20px;
    background-color: #5c6de0;
    border-radius: 14.18px;
    color: #fff;
    margin: 8px;
    flex: 1;
    display: flex;
    align-items: center;
    .sub-heading {
      font-size: 12px;
      font-weight: 500;
    }
    .heading {
      font-size: 18px;
      font-weight: 700;
      max-width: 12.5rem;
    }
    .description,
    .time-taken {
      font-size: 14px;
      font-weight: 400;
    }
  }
}