.cus-dropdown {
  position: absolute;
  top: 12px;
  background-color: white;
  border-radius: 15px;
  // box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: 150px;
  right: 0px;
  border: 2px solid #E5E7EB;
  .dropdown-item {
    padding: 7px 15px;
    border-bottom: 2px solid #E5E7EB;

    &:hover {
      background-color: #EBF5FF;
      color: #111827;
    }
    &:last-child {
      border-radius: 0px 0px 15px 15px;
      border-bottom: 0px;
    }
  }
}
