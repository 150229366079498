.blueBtn {
  button {
    padding: 10.7px 25px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid;
    background: #0040F0;
    color: #fff;
  }
}

.whiteBtn {
  display: flex;
  justify-content: flex-end;
  button {
    padding: 11px 22.7px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 10px;
    border: 1.5px solid;
    background: #fff;
    color: #4b5563;
    border-color: var(--grey-1);
  }
}
