.lesson-styleCard {
  border-radius: 12px;
  border: 1px solid #d1d5db;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.left-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 0.55;
}
.right-container {
  width: 40%;

  .upload-file-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}
