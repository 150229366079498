.attachment-container {
    li::marker {
        font-weight: bold;
    }
}

.attachment-title,
.attachment-footer {
    margin-left: 0.5rem;
}

.attachment-title {
    .attachment-link {
        cursor: pointer;
    }
}

.attachment-footer {
    font-size: 12px;
}

.student-attachments-wrapper {
    margin: 27px 20px;
}

.separator::before {
    content: '|';
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: #000; /* Change color as needed */
}

.module-dropdown {
    padding: 10.7px 10px !important;
    border-radius: 15px !important;
    min-width: 14rem;
}