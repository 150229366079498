.pdf-page {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
}

.pdf-page-separator {
  width: 100%;
  background-color: #f0f0f0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}
