.attach-file-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.attachment-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .attachment-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #374151;
  }

  .attachment-subtext {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #374151;
  }

  .trash-icon {
    cursor: pointer;
  }
}

.attach-file-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  .image-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1f2937;
  }

  .upload-file-btn {
    border-radius: 12px;
    color: #0040F0;
    background-color: white;
    border: 1.5px solid #e5e7eb;
    padding: 0px 24px 0px 24px;
    font-size: 14px;
    font-weight: 700;
    line-height: 48px;
  }

  .preview-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .preview-image {
    width: 44px;
    height: 44px;
    object-fit: cover;
  }
  .image-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
    width: 300px;
  }

  .file-preview-name-wrapper {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
    width: 80%;
  }
}
