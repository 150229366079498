.student-wrapper {
  background: url('../../../../public/images/studentIsland.svg') no-repeat
    center center fixed;
  width: 100%;
  height: 100%;
  -webkit-background-size: 100% 100vh;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100vh;
  // position: fixed;
  @media only screen and (max-width: 1370px) {
    background-size: cover;
  }
  @media screen and (min-aspect-ratio: 2.73/1) {
    background-size: cover;
  }
  .student-welcome-body {
    margin: auto;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;

    .token-progress-wrapper {
      width: 25%;
      margin: auto;
      padding-top: 20px;
    }
    .starting_progress {
      padding-top: 20px;
    }
    .logo {
      margin-bottom: 6%;
      img {
        @media only screen and (max-width: 1200px) {
          width: 147px;
        }
        @media only screen and (max-width: 950px) {
          width: 73px;
        }
      }
    }
    .welcome-text {
      font-size: 28px;
      line-height: 44px;
      @media only screen and (max-width: 950px) {
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 0px;
      }
    }
    button {
      background-color: #ffab0b;
      border: 2.24px solid #000;
      border-radius: 26px;
      box-shadow: 0px 2.240000009536743px 4.480000019073486px 0px #00000024;
      margin-top: 3%;
      @media only screen and (max-width: 950px) {
        margin-top: 5%;
      }
    }
    .btn-text {
      @media only screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
  }
}
