.islandsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0px 20px 15px 20px;

  .islandCardDetailsWrapper {
    border: 1.5px solid #e5e7eb;
    border-radius: 8px;
    padding: 30px 24px 30px 24px;
    display: flex;
    align-items: center;
    gap: 20px;
    transition: background-color 0.3s;

    .detailsContainer {
      flex: 1;

      .detailsWrapper {
        display: flex;
        gap: 16px;
        align-items: center;

        .islandName {
          font-size: 20px;
          font-weight: 600;
          color: #4c4c4c;
          line-height: 29.2px;
        }
        .islandIcon {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: 1.5px solid #e5e7eb;
        }
      }
    }

    .sectionsContainer {
      flex: 1;
      .sectionName {
        font-size: 14px;
        font-weight: 600;
        color: #4b5563;
        line-height: 20.44px;
      }
    }

    .actionsContainer {
      display: flex;
      align-items: center;
      gap: 25px;

      .dropdownAction {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 10px;
        gap: 10px;
      }

      .selected-dropdownAction {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f3f4f6;
        padding: 10px;
        gap: 10px;
      }
    }

    .desc {
      font-size: 14px;
      font-weight: 400;
      color: #808080;
      line-height: 21px;
    }

    .menu-wrapper {
      margin-top: 8px;
    }
  }

  .islandCardDetailsWrapper:hover {
    background-color: #f3f4f6;
  }
}
