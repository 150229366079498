.student-fantasy-section-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    .play-buttons {
      display: flex;
      bottom: 39%;
      .play-button {
        > img {
          height: 8%;
          position: absolute;
        }
        .islandIcon {
          margin: auto;
          position: absolute;
        }
        &.underwater_city {
          > img,
          .islandIcon {
            left: 72%;
            top: 75%;
          }
        }
        &.inside_volcano {
          > img,
          .islandIcon {
            top: 49%;
            left: 10%;
          }
        }
        &.cosmic_dance {
          img,
          .islandIcon {
            top: 49%;
            left: 57%;
          }
        }
        &.outer_space{
          img,
          .islandIcon {
            top: 27%;
            left: 30%;
          }
        }
        &.message_in{
          img,
          .islandIcon {
            top: 75%;
            left: 42%;
          }
        }
        &.flying_carpet{
          img,
          .islandIcon {
            top: 29%;
            left: 85%;
          }
        }
      }
    }
    .fantasyHelp {
      left: 34%;
      position: fixed;
      z-index: 43;
      bottom: 23%;
      width: 9%;
    
    }
    .student-section-container {
      height: 100vh;
      width: 100%;
    }
  }
  
  // .student-urban-section-wrapper {
  //   position: relative;
  // }
  
  .scroll-container {
    overflow-x: scroll;
    // white-space: nowrap;
  }
  