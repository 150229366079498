.student-island-wrapper {
  background: url('../../../../public/images/islandBg.png') no-repeat center
    center fixed;
  width: 100%;
  height: 100%;
  -webkit-background-size: 100% 100vh;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100vh;
  .student-island-container {
    align-items: center;
    display: flex;
    justify-content: CENTER;
    height: 100%;
  }
}
.islandCar {
  left: 0%;
  position: fixed;
  z-index: 43;
  bottom: 0%;
  width: 11%;
}
.island-car-tooltip {
  position: relative;
  .tooltip-wrapper {
    bottom: 6%;
    left: 20%;
    position: fixed;
  }
}

@media screen and (orientation: portrait) {
  @media screen and (max-width: 812px) {
    .student-island-wrapper {
      background: url('../../../../public/images/rotate-screen.jpg') no-repeat
        center center fixed;
      height: 100vh;
      background-color: #000;

      .student-island-container,
      .island-car-tooltip {
        display: none;
      }
    }
  }
}
