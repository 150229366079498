.search-bar {
  align-items: center;
  position: relative;
  input {
    height: 44px;
    border-color: var(--grey-1);
    border-width: 1.5px !important;
    padding-left: 45px !important;
    border-radius: 12px !important;
    color: var(--grey3);
    font-size: 14px;
    width: 100%;
    font-family: var(--font-inter-regular);
    font-weight: 500;
  }
  img {
    position: absolute;
    left: 16px;
  }
}
