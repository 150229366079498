.student-urban-section-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  .play-buttons {
    display: flex;
    bottom: 39%;
    .play-button {
      > img {
        height: 8%;
        position: absolute;
      }
      .islandIcon {
        margin: auto;
        position: absolute;
      }
      &.urban_garden {
        > img,
        .islandIcon {
          left: 5%;
          top: 65%;
        }
      }
      &.manhole_cover {
        > img,
        .islandIcon {
          top: 71%;
          left: 49%;
        }
      }
      &.basketball_court {
        img,
        .islandIcon {
          top: 44%;
          left: 60%;
        }
      }
      &.community_pantry {
        img,
        .islandIcon {
          top: 48%;
          left: 91%;
        }
      }
    }
  }
  .urbanHelp {
    left: 19%;
    position: fixed;
    z-index: 43;
    top: 23%;
    width: 9%;
  }
  .student-section-container {
    height: 100vh;
    width: 100%;
  }
}

// .student-urban-section-wrapper {
//   position: relative;
// }

.scroll-container {
  overflow-x: scroll;
  // white-space: nowrap;
}
