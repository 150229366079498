.add-student-wrapper {
    margin: 20px;
    .cus-form-header {
        margin: 30px 0px 40px;
        font-family: var(--font-inter-regular);

       h4{
        font-size: 24px;
        color:#374151;
        font-weight: 700;
       }
       span{
        font-size: 16px;
        color:#111827;
        font-weight: 400;
       }
    }
    .upload-file-wrapper {
        gap:20px;
        .upload-file-btn{
          line-height: 36px;
        }
      }
}