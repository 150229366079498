.avatarsContainer {
  display: flex;
  align-items: center;
  margin: 0px 15% !important;
  .avatarCardDetailsWrapper {
    margin-top: 2%;
    margin-bottom: 4%;
    @media only screen and (max-width: 1024px) {
      margin-top: 1%;
      margin-bottom: 2%;
    }
    .detailsContainer {
      .detailsWrapper {
        position: relative;
        width: fit-content;
        margin: auto;
        .fix-frame {
          width: 65%;
          position: relative;

          @media only screen and (max-width: 1200px) {
            width: 63%;
          }
          @media only screen and (max-width: 900px) {
            width: 57%;
          }
        }
        .avatarIcon {
          margin: auto;
          bottom: 0;
          position: absolute;
          left: 0;
          right: 0;
          width: 43%;
          height: 43%;
          top: 0;
          object-fit: contain;
        }
      }
    }
  }
}
