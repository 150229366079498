.upload-file-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  .img-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    flex: 0.4;
  }

  .image-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1f2937;
  }

  .upload-file-btn {
    border-radius: 12px;
    color: #0040F0;
    background-color: white;
    border: 1.5px solid #e5e7eb;
    padding: 0px 19px;
    font-size: 14px;
    font-weight: 700;
    line-height: 48px;
  }

  .preview-container {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 0.6;
  }

  .preview-image {
    width: 44px;
    height: 44px;
    object-fit: cover;
  }
  .image-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
