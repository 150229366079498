.weekly-progress-card {
  background-color: #3f4dc3;
  display: flex;
  border-radius: 24px;
  padding: 9px;
  align-items: center;
  .progress-icon {
    padding: 13px;
    border-radius: 17px;
    background: #5c6de0;
    margin: 7px;
  }
  .progress-data {
    width: 100%;
    color: #fff;
    .weekly-title {
      font-size: 16px;
    }
    .weekly-numbers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .value {
        font-size: 22px;
        font-size: 700;
      }
      .inc-dec {
        border-radius: 16px;
        border: 1px solid #fff;
        padding: 2px 13px;
        font-size: 14px;
        &.red span{
          color:#EF4444;
        }
        &.green span{
          color: #22C55E;
        }
      }
    }
  }
}
