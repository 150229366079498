.content-wrap {
  border: 0;
  font-weight: bold;
  margin: auto;
  display: block;
  border-radius: 26.88px;
  position: relative;
  outline: none;
  padding: 0px 4px 4px 4px;
  cursor: pointer;
  width: 100%;
}

.content-wrap-inner {
  position: relative;
  padding: 0.59rem 1.75rem;
  display: block;
  border-radius: 26.88px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
