.dialogActionsWrapper {
  width: 100%;
  margin-left: -3%;
  @media only screen and (max-width: 950px) {
    margin-left: -4%;
  }
}

.student_blue_modal {
  background-color: #38469e !important;
  .student_blue_modal_inner {
    background-color: #3f4dc3;
    border-radius: 7px;
    padding: 10px;
  }
}