.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 22px;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
  }
}
.pagination-btn {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  button {
    padding: 9px 14px;
    border-radius: 12px;
    border-color: var(--grey-1);
    border-width: 1.5px !important;
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    color: #374151;
  }
}

.custom-table {
  border: 2px solid #e5e7eb;
  margin: 20px;
  border-collapse: separate;
  border-radius: 15px;
  width: -webkit-fill-available !important;
  .custom-table-header{
    position: relative;
    z-index: 0;
  }
  tr:last-child {
    td {
      border: none;
    }
  }
  th {
    background-color: #f3f4f6;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid #e5e7eb !important;
    text-align: left;
    font-family: var(--font-inter-regular) !important;

    @media only screen and (max-width: 1024px) {
      padding: 10px 14px;
      font-size: 12px;
    }
    &:first-child {
      border-radius: 10px 0px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 10px 0px 0px;
    }
    span {
      color: #4b5563;
      font-weight: 600;
      font-family: var(--font-inter-regular) !important;
    }
  }
  tbody {
    td {
      font-family: var(--font-inter-regular) !important;
      padding: 16px !important;
      span {
        color: #6b7280;
      }
    }
    .MuiTableRow-hover:hover {
      background-color: transparent;
    }
  }
  .actions-wrapper {
    display: flex;
    justify-content: space-between;
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        stroke: #0040F0;
        fill: #0040F0;
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
    }
  }
  .edit-delete {
    margin: auto;
    display: flex;
    cursor: pointer;
    svg {
      width: 40px;
      height: 40px;
      padding: 7px;
    }
    &.selected-action {
      svg {
        background-color: #f3f4f6;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 7px;
      }
    }
  }
}
