.add-school-wrapper {
  margin-bottom: 90px;
  h2 {
    font-size: 14px;
    color: #6b7280;
    font-weight: 500;
    text-align: center;
    padding: 20px 0px;
  }
  form {
    margin: 20px;
  }
  .upload-file-wrapper {
    gap:20px;
    .upload-file-btn{
      line-height: 36px;
    }
  }
}
.schoolFormBtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap:10px;
}
.progress-bar {
  text-align: center;
  width: 50%;
  margin: 20px auto;
  position: relative;
  z-index:5;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  .counter {
    position: relative;
    .step {
      display: inline-block;
      border: 1px solid grey;
      border-radius: 50%;
      width: 2em;
      line-height: 1.88em;
      color: #d1d5db;
      background-color: #fff;
      z-index: 9;
      position: relative;
      svg {
        margin-top: 0px;
      }
    }
    .step_title{
      font-size: 14px;
      color:#4B5563;
      font-weight: 500;
      margin-top:10px;
    }
    .active .step {
      border: 1px solid #2b53ff;
      color: #2b53ff;
    }
    span {
      position: absolute;
      top: 15px;

      width: 30.5%;
      border-top: 2px solid grey;
    }
    .filled .step,
    .filled span {
      border-top: 1px solid #2b53ff;
      background-color: #2b53ff;
      color: #fff;
      border-radius: 50%;
    }
  }

  .descriptions,
  .counter {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
  .descriptions {
    div {
      font-size: 20px;
    }
  }
}
.add-school-dropdown {
  select {
    border: none;
    width: 100%;
  }
}

.setting_form {
  p {
    font-size: 14px;
    color: #4b5563;
  }
  label {
    color: #374151;
  }
  .toggle-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .license-date-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .license-date {
      width: 91%;
      cursor: pointer;
    }
  }
}
.admin-form-wrapper,.setting_form{
  margin-top: 120px;
}

.error-message {
  padding-top: 5px;
  color:Red;
}