.cus-form-label {
  font-size: 16px;
  font-weight: 600;
  color: #374151;
  font-family: var(--font-inter-regular);
}

.input-innerlabel-container {
  position: relative;
  display: flex;
  align-items: center;

  input:focus {
    box-shadow: none;
    outline: none;
  }
  input:disabled {
    cursor: not-allowed;
  }
}

.input-end-adornment {
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.form-control {
  &.end-adornment {
    padding-right: 100px;
  }
}
