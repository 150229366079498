.actions-button {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
  margin: 50px 10px 0px 10px;

  &.center-btn {
    justify-content: center;
  }
}

.modal-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 600;
  padding: 0px 12px;
}

.dialogueTitleStyle {
  color: #1F2937;
  text-align: center;
}

.MuiDialog-root{
  z-index: 1400 !important;
}