.cus-select {
  select {
    background: transparent !important;
  }
  &.disabled{
    background: #e9ecef !important;
  }
  &.tab-select-bar {
    padding: 9.2px;
    border-radius: 10px;
    font-family: var(--font-inter-regular);
    font-size: 14px;
    select {
      color: var(--grey3);
    }
  }
}
