.dashboard-islandProgress-inner {
  width: 100%;
  overflow: hidden;
  height: 15px;
  position: relative;
  background-color: #e5e7eb;
  border-radius: 28px;
}

.dashboard-progress-bar {
  overflow: hidden;
  height: 15px;
}

.dashboard-progress-bar-s {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.progressTab {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .progress-topSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .nameIsland {
      font-weight: 700;
      font-size: 16px;
      line-height: 19.36px;
      color: #111827;
    }

    .progressIsland {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #030712;
    }
  }
}
