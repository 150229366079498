.star-progress-btn-wrapper {
  position: relative;
  width: 3.8vw;
  height: 4.8vh;
  overflow: hidden;
  margin: auto;
  aspect-ratio: 1;
  @media only screen and (max-width: 1400px) {
    width: 5vw;
    height: 4vh;
  }
  @media only screen and (max-width: 1360px) {
    width: 3.8vw;
  height: 4.8vh;
  }

  @media only screen and (max-width: 1024px) {
    width: 6vw;
    height: 4vh;
  }

  @media only screen and (max-width: 950px) {
    width: 4vw;
    height: 5vh;
  }
  @media only screen and (max-width: 850px) {
    width: 4vw;
    height: 4vh;
  }
  @media screen and (max-aspect-ratio: 0.75/1) {
    width: 5vw;
    height: 2vh;
  }
}
.star-progress-btn-outer {
  width: fit-content;
  height: fit-content;
}

.starProgress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: width 0.5s ease;
  background-image: url('../../../public/images/yellowstars.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.star-progress-bg {
  aspect-ratio: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/greystars.svg');
  background-repeat: no-repeat;
  filter: grayscale(100%);
  transition: filter 0.5s ease;
  background-size: cover;
  @media only screen and (max-width: 120px) {
    background-size: calc(100vw * 0.052);
  }
}

.star-progress-btn-wrapper:hover .star-progress-bg {
  filter: grayscale(0%);
}

.star-progress-btn-outer {
  position: relative;
  .active-button {
    width: 97%;
    @media only screen and (max-width: 1200px) {
      width: 75%;
    }
  }
  .button-count-wrapper {
    right: 0;
    text-align: center;
    position: absolute;
    margin: auto;
    top: 73%;
    width: max-content;
    left: 0;
    .button-count {
      right: 0;
      font-size: 27px;
      color: #fff;
      width: 100%;
      text-align: center;
      position: absolute;
      left: 0;
      top: 10%;
      margin: auto;
      font-weight: 700;
      @media only screen and (max-width: 1200px) {
        font-size: 15px;
        top: 20%;
      }
      @media only screen and (max-width: 950px) {
        top: 15%;
      }
    }
    @media only screen and (max-width: 991px){
      width: fit-content;
    }
  }
}
