.all-notifyAnnouncements-wrapper {
  display: flex;
  width: 100%;
}

.notify-announcements-tabsWrapper {
  width: 100%;
  margin: 27px 20px;
  .notify-popup-title{
    display: block;
  }
  .announcement-width{
    .img-message{
      max-width: 100%;
    }
  }
}
