.card-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.header-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .header-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #374151;
  }
  .trash-icon {
    cursor: pointer;
  }
}

.card-content {
  border: 1.5px solid #e5e7eb;
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 12px;

  .card-content-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #111827;
    width: 100%;
    border: none;
    word-wrap: break-word;
    overflow: 'auto';
    white-space: 'pre-wrap';
  }
}
