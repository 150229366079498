.no-content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title-row {
  display: flex;
  justify-content: center;
  .text {
    font-size: 24px;
    color: #6b7280;
    font-weight: 600;
  }
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
}

.content-text {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
}
