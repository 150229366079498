.add-content-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 27px 20px;
  gap: 10px;
}

.first-column {
  width: 67%;
  border-radius: 24px;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
  position: relative;
  padding: 16px;
  overflow-y: auto;
  max-height: 100%;
}

.centered-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-column {
  width: 30%;
  border-radius: 24px;
  background-color: #ffffff;
  height: 95%;
  z-index: 1;
  top: 27px;
  position: absolute;
  right: 20px;
  overflow: auto;
  padding-bottom: 15%;
}

// Drag And Drop
/* Add this CSS to your styles */
.sortable-item-handle {
  cursor: grab;
  padding: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  display: inline-block;
  margin-right: 10px;
  user-select: none;
}

.sortable-item-handle:active {
  cursor: grabbing;
}

// .handle-icon {
//   font-size: 20px;
//   cursor: grab;
// }

// .handle-icon:active {
//   cursor: grabbing;
// }

.drop-area {
  width: 100%;
  height: 100px;
  color: #dcdcdc;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.hide-drop-area {
  opacity: 0;
  margin-bottom: -15px;
}
