.dashboard-menu-list {
  align-items: center !important;
  .menu-item {
    margin-bottom: 8px !important;
    padding: 10px !important;
    cursor: pointer;
    img {
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }
    span{
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        display: none;
      }
    }

    &.active {
      background: #02ae85;
      padding: 10px;
      border-radius: 10px;
    }
  }
}

.sidebar-logo {
  width: 100%;
  padding: 15px;
}
