
  .student_blue_modal {
    .student_blue_modal_inner {
      background-color: transparent;
      padding: 0px;
      .student-setting-modal-wrapper{
        .container-fluid{
          padding: 0px;
        }
      }
    }
  }
  

