.image-video-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.attachment-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .attachment-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #374151;
  }
  .trash-icon {
    cursor: pointer;
  }
}

.file-show-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  .preview-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1.5px solid #e5e7eb;
    border-radius: 12px;
    gap: 20px;
    padding: 12px 16px;
    width: 100%;
    flex: 1;

    .file-name {
      font-size: 14px;
      line-height: 20px;
      color: #4b5563;
      max-width: 260px;
      overflow-wrap: break-word;
      white-space: normal;
    }

    .file-preview {
      width: 165px;
      height: 150px;
      border: 1px solid #e5e7eb;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.image-video-dropzone {
  border: 1.5px dashed #e5e7eb;
  padding: 10px;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  width: 22%;
}

.preview-image {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.preview-video {
  width: 85%;
  height: 85%;
}
