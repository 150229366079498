.student-dashboard {
  .blue-card {
    background: #3f4dc3;
    border-radius: 24px;
    padding: 20px;
    height: 338px;
    h3 {
      font-size: 22px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .module_stats {
      background: #5c6de0;
      border-radius: 24px;
      padding: 32px 20px;
      display: flex;
      align-items: center;
      .value {
        background: #3f4dc3;
        border-radius: 17px;
        padding: 9.5px 19px;
        font-size: 22px;
        font-weight: 700;
        color: #fff;
      }
      .title {
        font-size: 16px;
        color: #fff;
        margin-left: 15px;
      }
    }
  }
  .progressTab .progress-topSection {
    .nameIsland,
    .progressIsland {
      color: #fff;
      font-size: 14px;
    }
    .nameIsland{
        font-weight: 500;
    }
  }
}

.student-dash-progress{
  gap:10px !important;
  margin-bottom: 20px;
}