.help-card-inner {
  position: relative;
  img {
    right: 15px;
    width: 17px;
    position: absolute;
    cursor: pointer;
    transform: rotate(180deg);
    bottom: 41px;
  }
}
.bookmark-tabs {
  .MuiButtonBase-root {
    color: #87a5f2;
    &.Mui-selected {
      color: #fff !important;
    }
  }
  .MuiTabs-indicator {
    background-color: #fff !important;
  }
}
