.student-header-wrapper {
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1400;
  padding: 1% 3%;

  .student-left-header {
    align-items: center;
    display: flex;
    gap: 10px;
  }
  .token-progress-wrapper {
    width: 12%;
  }
}

.student-left-header {
  img{
      width: 48px;
      height: 48px;
  }
}

.close-button {
  background: linear-gradient(#ffcc00, #ff9900) padding-box, linear-gradient(#fbfbfb, #050505) border-box;
  border: 1.5px solid transparent;
  border-radius: 50%;
  width: 35.5px;
  height: 35.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 1.65px rgb(0, 0, 0);
  color: white;
  font-size: 22px;
  font-weight: bolder;
  transition: background 0.3s ease;
  position: relative;
  bottom: 2px;
  padding: 0;
  left: 6px;
  div {
    border: 1.5px solid transparent;
    border-radius: 50%;
    background: linear-gradient(#ffab0b, #ffab0b) padding-box, linear-gradient(#ffcc23, #ff7902) border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    span {
      position: relative;
      bottom: 1px;
    }
  }
}

// .close-button:hover {
//   background: linear-gradient(90deg, #ff9900, #ffcc00);
//   transition: background 0.3s ease;
// }
