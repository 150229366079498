.bulk-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  // margin-bottom: 25px;
  padding: 0px 12px;
}

.bulkTitleStyle {
  color: #1f2937;
}

.close-icon {
  cursor: pointer;
}

.bulk-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 10px;
  margin: 15px 30px;
}

.step-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.upload-step-label {
  text-align: center;
  span {
    display: inline-block;
    font-weight: 600;
    color: #1f2937;
    text-transform: none;
    font-size: 1rem;
    letter-spacing: 1px;
  }
}

.dropzone {
  border: 1px dashed #1f2937;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  width: 80%;
  margin: auto;
}
