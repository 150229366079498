.toggle-button {
    border: none;
    outline: none;
    background-color: #fff;
    color: #9CA3AF;
    padding: 4px 20px;
    cursor: pointer;
    border-radius: 20px;
    height: 26px;
    position: relative;
    border: 1px solid #9CA3AF;
  }
  
  
  
  .toggle-button:after{
    content: "";
    display: inherit;
    width: 14px;
    height: 14px;
    border-radius: 12px;
    background: #9CA3AF;
    transition: 0.3s;
    position: absolute;
    left: 4px;
    top:5px
  }
  .toggle-button.active:after{
    content: "";
    display: inherit;
    width: 14px;
    height: 14px;
    border-radius: 12px;
    background-color: #fff;
    color: white;
    transition: 0.3s;
    position: absolute;
    right: 4px;
    top:5px;
    left: auto;
  }
  .toggle-button.active{
    background-color: #0B63E5;
    border: 1px solid #0B63E5;
  }