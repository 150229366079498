.dashboard-searchFilterWrapper {
  justify-content: space-between;
  margin: 25px 0px;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
  .filter-wrapper {
    align-items: center;
    border: 1.5px solid #e5e7eb;
    border-radius: 12px;
    select {
      padding: 0px 20px 0px 20px;
      border: none;
      margin: 0px 20px 0px 0px;
      height: 44px;
      color: #4b5563;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      font-family: var(--font-inter-semibold);
      background-color: #ffffff;
    }
  }
  .searchbar-inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    .search-bar input {
      width: 270px;
    }
  }

  .filter-firstTab {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: hidden;
  }

  .filter-lastTab {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }

  .divider {
    width: 1.5px;
    height: 44px;
    background-color: #e5e7eb;
  }
}
