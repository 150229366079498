@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: var(--font-inter), sans-serif !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebar {
  width: 18%;
}
:root {
  --font-inter: 'Inter', sans-serif;
  --font-montserrat: 'Montserrat', sans-serif;
  --primary-color: #0040F0;
  --secondary-color: #0d1539;
  --faded-blue: #ebf5ff;
  --grey-1: #e5e7eb;
  --grey2: #838e9e;
  --grey3: #6b7280;
  --black1: #030712;
  --black2: #1f2937;

  --font-inter-extraLight: var(--font-inter) 200;
  --font-inter-light: var(--font-inter) 300;
  --font-inter-regular: var(--font-inter) 400;
  --font-inter-medium: var(--font-inter) 500;
  --font-inter-semibold: var(--font-inter) 600;
  --font-inter-bold: var(--font-inter) 700;
  --font-inter-extrabold: var(--font-inter) 800;
  --font-inter-black: var(--font-inter) 900;
}

.inter-extraLight {
  font: var(--font-inter-extraLight) normal;
}

.inter-light {
  font: var(--font-inter-light) normal;
}

.inter-regular {
  font: var(--font-inter-regular) normal;
}

.inter-medium {
  font: var(--font-inter-medium) normal;
}

.inter-semibold {
  font: var(--font-inter-semibold) normal;
}

.inter-bold {
  font: var(--font-inter-bold) normal;
}

.inter-extrabold {
  font: var(--font-inter-extrabold) normal;
}

.inter-black {
  font: var(--font-inter-black) normal;
}

.montserrat {
  font-family: var(--font-montserrat);
}
.cursor {
  cursor: pointer;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

::-webkit-scrollbar {
  width: 3px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #afafaf;
  border-radius: 10px;
}
.montserrat-bold {
  font-family: var(--font-montserrat);
  font-weight: 900;
  text-shadow: -1px 5px 2px #080206, 0 2px 1px #080206, 2px 0 1px #080206,
    0 -2px 1px #080206;
  -webkit-text-fill-color: #fff;
}
.text-shadow{
  text-shadow: 2px 2px #00000075;
}
.w-20 {
  width: 20% !important;
}
.w-60{
  width: 60% !important;
}
.cursor-none, .disabled{
  cursor: not-allowed;
}
