.module-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .module-name {
    flex-grow: 1;
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize;
    text-shadow: 0px 1px 5px rgba(136, 13, 13, 0.2),
      0px 3px 4px rgba(123, 12, 12, 0.12), 0px 2px 4px rgba(91, 26, 26, 0.14);
    @media only screen and (max-width: 950px) {
      font-size: 20px;
    }
  }

  .module-header-right-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    .lesson-readtime {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
    }
  }
}
