.three-d-button {
  border: 0;
  font-weight: bold;
  margin: auto;
  display: block;
  border-radius: 26.88px;
  position: relative;
  outline: none;
  padding: 4px;
  cursor: pointer;
  //   width: 100%;

  border: 3px solid #0a0a0a;
  &.yellowBtn {
    background: linear-gradient(178deg, #ffe015 10%, #ff8800 100%);
  }
  &.blueBtn {
    background: linear-gradient(178deg, #8fa4f8 10%, #2337d2 100%);
  }
  &.greyBtn {
    background: linear-gradient(178deg, #acacac 10%, #535353 100%)
  }
  &:disabled{
    opacity: 0.5;
  }
}

.btn-text {
  color: #fff;
  font-size: 22.4px;
  line-height: 31.36px;
}

.three-d-button:before,
.three-d-button:after,
.three-d-button span:before,
.three-d-button span:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 26.88px;
}

.btn-inner {
  //   width: 100%;
  position: relative;
  padding: 0.59rem 1.75rem;
  @media only screen and (max-width: 900px) {
    padding: 2px 16px;
  }
  display: block;
  border-radius: 26.88px;
  position: relative;
  &.yellowInner {
    background: linear-gradient(
      170deg,
      #ffab0b 0%,
      #ffab0b 50%,
      #ffab0b 95%,
      #ffab0b 100%
    );
  }
  &.blueInner {
    background: linear-gradient(
      170deg,
      #5c6de0 0%,
      #5c6de0 50%,
      #5c6de0 95%,
      #5c6de0 100%
    );
  }
  &.greyInner {
    background: linear-gradient(
      170deg,
      #7e7e7e 0%,
      #7e7e7e 50%,
      #7e7e7e 95%,
      #7e7e7e 100%
    );
  }
}
