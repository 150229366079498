.staffQuesAnsListContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .questionsAskedLabel {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #1f2937;
  }

  .staffQuesAnsWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;

    .staffQuesIndex {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #1f2937;
    }

    .pin-star {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .staffQuesAnsDetailsContainer {
      flex: 1;

      .quesAnsDetails {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .staffQuesText {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #1f2937;
        }

        .staffQuesText::first-letter {
          text-transform: uppercase;
        }

        .staffAnsText {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #374151;
        }

        .staffAnsText::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}

.quesAskedByWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .askByDetailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .askByText {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #374151;
    }

    .askByPerson {
      border-radius: 12px;
      padding: 0px 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14.52px;
      color: #0040F0;
      text-transform: capitalize;
    }

    .pinDot {
      width: 4px;
      height: 4px;
      color: #374151;
    }

    .askMonths {
      border-radius: 12px;
      padding: 0px 4px;

      .submitAnsText {
        font-weight: 600;
        font-size: 12px;
        line-height: 14.52px;
        color: #0040F0;
        cursor: pointer;
      }

      .askMonthsText {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #374151;
      }
    }
  }
}
