.lesson-complete-header {
  display: flex;
  justify-content: flex-end;
}

.complete-close-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.studentIslandModalButtons {
  .btn-inner {
    padding: 3px 15px;
    .btn-text {
      font-size: 14px;
    }
  }
}
