.horizontal-scroll-wrapper {
  position: fixed;
  bottom: 0;
  padding: 2rem 4rem;
  justify-content: space-around;
  gap: 15%;
  display: flex;
  width: 100%;
  button:disabled {
    opacity: 0.25;
    cursor: not-allowed;
    // img {
    //   opacity: 0.25;
    //   cursor: not-allowed;
    // }
  }
  button {
    // border: 0;
    // background: none;
    background: linear-gradient(-180deg, #ffcc00, #ff9900);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 2px 2.5px rgb(0, 0, 0);
    color: white;
    font-size: 22px;
    transition: background 0.3s ease;
    position: relative;
    img {
      width: 2.5rem;
    }
  }
  button:hover {
    background: linear-gradient(90deg, #ff9900, #ffcc00);
    transition: background 0.3s ease;
  }
}