.colorPicker-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid-colorContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 10px;
}

.grid-colorItem {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.text-backgroundColor {
  font-size: 14px;
  line-height: 24px;
  color: #1f2937;
  font-weight: 600;
}

.text-hex {
  display: flex;
  gap: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #1f2937;
  font-weight: 500;
  border-radius: 12px;
  background-color: #f3f4f6;
  padding: 10px 20px;
}

.color-picker-icon {
  cursor: pointer;
}
