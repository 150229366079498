.student-ques-ans-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.searchQuestions-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;

  .searchQues-btn {
    .btn-inner {
      padding: 2.35px 39px;
      .search-btn-label {
        font-size: 20px;
      }
    }
  }
}

.searchQues-contentWrapper {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;

  .quesSearchInputField {
    flex: 1;
    background: none;
    border: none;
  }

  textarea.quesSearchInputField:focus-visible {
    outline: none;
  }

  textarea.quesSearchInputField {
    min-height: 85px;
  }
}

.outer-searchdoc-container {
  background: linear-gradient(
    180deg,
    #eeeeee 0%,
    #d8d5d5de 50%,
    rgb(181 184 188 / 50%) 95%,
    rgba(181, 184, 188, 0.55) 100%
  );
}

.inner-searchdoc-container {
  background: linear-gradient(178deg, #e5e7eb26 10%, #e5e7eb 100%);
  padding: 7px;
}

.addNewQues-btn {
  align-self: flex-end;
}

.quesAns-container {
  display: flex;
  flex-direction: column;
  gap: 18px;

  .quesTypeHeaderText-container {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #1f2937;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .quesTypeHeaderText {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #1f2937;
    }
  }
}

.add-question-btn {
  .btn-inner {
    padding: 2.35px 18px;
    .btn-text {
      font-size: 20px;
    }
  }
}

.deleteModal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.delete-text {
  font-size: 16px;
  line-height: 48px;
  font-weight: 900;
  color: #ffffff;
  text-align: center;
}
