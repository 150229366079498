.student-profile-right {
  background-color: #3f4dc3;
  border-radius: 10px;
  padding: 20px;
  max-height: 240px;
  .student-profile-wrapper {
    display: flex;
    flex-direction: column;
    img {
      width: 80px;
      height: 80px;
      margin: auto;
      margin-bottom: 10px;
      border-radius: 50%;
    }
    a {
      font-size: 20px;
      font-weight: 700;
      color: #fff !important;
      padding: 3px 10px;
      margin: 10px 0;
      @media only screen and (max-width: 1024px) {
        font-size: 13px;
      }
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        margin-bottom: 0px;
        @media only screen and (max-width: 1024px) {
            margin-right: 5px;
          }
      }
      &.active {
        border-radius: 25px;
        background: #5c6de0;
        padding: 3px 10px;
        text-decoration: none;
      }
    }
  }
}
