.addAnswerStaffWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.addStaff-quesAnsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  .addAns-quesIndex {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #1f2937;
  }

  .addAnsStaff-details {
    flex: 1;
  }

  .submitQues-AnsWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .addAns-quesText {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #1f2937;
    }

    .addAns-quesText::first-letter {
      text-transform: uppercase;
    }

    .inputAnswer-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .submit-ansText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
  }
}

.quesAns-actionsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .askBy-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .askByText {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #374151;
    }

    .askByPerson {
      border-radius: 12px;
      padding: 0px 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14.52px;
      color: #0040F0;
      text-transform: capitalize;
    }

    .pinDot {
      width: 4px;
      height: 4px;
      color: #374151;
    }

    .askMonths {
      border-radius: 12px;
      padding: 0px 4px;

      .askMonthsText {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #374151;
      }
    }
  }
}

.submit-ansText::first-letter {
  text-transform: uppercase;
}
