.article-card-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.article-card-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .article-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #374151;
  }
  .trash-icon {
    cursor: pointer;
  }
}

.add-article-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border: 1.5px solid #e5e7eb;
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 12px;

  .article-content-text {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .article-link {
      color: #0040F0;
      text-decoration: underline;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .article-title-text {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #111827;
      width: 100%;
    }
  }

  .article-prev-container {
    width: 70px;
    height: 70px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e5e7eb;
    .article-preview-img {
      width: 64px;
      height: 64px;
      object-fit: cover;
    }
  }
}
