.SideBarWrapper {
  height: 100%;
  position: fixed !important;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;
  width: 18.33%;
  @media only screen and (max-width: 1200px){
    width: 12%;
  }
}
.rightBodySection {
  margin-left: calc(100% - 81.66%) !important;
  background-color: #fff !important;
  width: 81.66%;
  @media only screen and (max-width: 1200px){
    width: 90%;
  }
}
