.s_name_address {
  text-align: left;
  text-transform: capitalize;
  .s_name {
    font-size: 14px;
    font-weight: 500;
    color: #111827;
  }
  .s_address {
    font-size: 14px;
    font-weight: 400;
    color: #6b7280;
  }
  .s_logo {
    img {
      margin-right: 10px;
      object-fit: cover;
      height: 40px;
      border-radius: 50%;
      width: 40px;
      border: 1px solid #E5E7EB;
    }
  }
}
.search-filter-wrapper {
  justify-content: space-between;
  margin: 25px 20px;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
  .filter-wrapper {
    align-items: center;
    border-radius: 12px;
    border: 2px solid #e5e7eb;
    height: 44px;
    .view-all {
      padding: 10px 14px;
      border-radius: 12px 0px 0px 12px;
      color: #4b5563;
      font-size: 14px;
      font-weight: 600;
      font-family: var(--font-inter-semibold);
    }
    select {
      padding: 0px 18px 0px 17px;
      border: none;
      margin: 0px 19px 0px 0px;
      border-left: 1.5px solid #e5e7eb !important;
      height: 41px;
      color: #4b5563;
      font-size: 14px;
      font-weight: 600;
      font-family: var(--font-inter-semibold);
      width: 98%;
    }
  }
  .searchbar-inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    .search-bar input{
      width:270px;
    }
  }
}
.sorting {
  button {
    display: flex;
    padding: 10.7px 10px;
    border-radius: 15px;
    border-color: var(--grey-1);
    border-width: 1.5px !important;
    background-color: #fff;
    color: #4b5563;
    font-family: var(--font-inter-regular);
    font-weight: 600;
    font-size: 14px;
    svg{
      width: 20px;
      height: 20px;
    }
  }
}

button {
  border-style: solid;
  &:disabled {
    cursor: not-allowed;
    background-color: #6b72802e;
  }
}
input,
select {
  border-style: solid;
  &:focus-visible {
    outline: none;
  }
}

.add-student-btn {
  display: flex;
  justify-content: flex-end;
  button {
    padding: 10.6px 22.7px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 10px;
    border: 1.5px solid;
    background: #fff;
    color: #4b5563;
    border-color: var(--grey-1);
  }
}

.import-btn {
  button {
    padding: 10.7px 25px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid;
    background: #0040F0;
    color: #fff;
  }
}

.student-detail-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 27px 20px;
  align-items: center;
  .school-label {
    font-size: 22px;
    font-weight: 600;
    color: #101828;
  }
}
.add-import-btn {
  // width: 23%;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
.school-active {
  text-transform: capitalize;
  padding: 4px 12px;
  color: #02ae85;
  background: #f5fffd;
  border-radius: 16px;
}
.school-deactivate {
  text-transform: capitalize;
  padding: 4px 12px;
  color: #ef4444;
  background: #fef6f6;
  border-radius: 16px;
}
