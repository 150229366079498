.tooltip-wrapper {
  border: 0;
  font-weight: bold;
  margin: auto;
  display: block;
  border-radius: 16px;
  position: relative;
  outline: none;
  padding: 4px;
  cursor: pointer;
  background: linear-gradient(178deg, #1a2989d4 10%, #0b1866 100%);
}

.tooltip-text {
  color: #fff;
  font-size: 14px;
  line-height: 21px;
}

.tooltip-wrapper:before,
.tooltip-wrapper:after,
.tooltip-wrapper span:before,
.tooltip-wrapper span:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
}

.tooltip-inner-wrapper {
  position: relative;
  padding: 0.44rem 1.75rem;
  @media only screen and (max-width: 900px) {
    padding: 2px 16px;
  }
  display: block;
  border-radius: 16px;
  position: relative;
  background: linear-gradient(
    170deg,
    #1a2989 0%,
    #1a2989 50%,
    #1a2989 95%,
    #1a2989 100%
  );
}
