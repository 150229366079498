.student-rural-section-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    .play-buttons {
      display: flex;
      bottom: 39%;
      .play-button {
        > img {
          height: 8%;
          position: absolute;
        }
        .islandIcon {
          margin: auto;
          position: absolute;
        }
        &.scarecrow_in {
          > img,
          .islandIcon {
            left: 30%;
            top: 53%;
          }
        }
        &.tractor_pull {
          > img,
          .islandIcon {
            top: 46%;
            left: 9%;
          }
        }
        &.rodeo {
          img,
          .islandIcon {
            top: 53%;
            left: 60%;
          }
        }
        &.relaxed_aliens {
          img,
          .islandIcon {
            top: 48%;
            left: 91%;
          }
        }
      }
    }
    .ruralHelp {
        left: 42%;
        position: fixed;
        z-index: 43;
        bottom: 8%;
        width: 13%;
    
    }
    .student-section-container {
      height: 100vh;
      width: 100%;
    }
  }
  
  // .student-urban-section-wrapper {
  //   position: relative;
  // }
  
  .scroll-container {
    overflow-x: scroll;
    // white-space: nowrap;
  }
  