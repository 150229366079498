.student-lesson-wrapper {
  width: 100%;
  height: 100%;
  -webkit-background-size: 100% 100vh;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100vh !important;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.lesson-bg-tidalpool {
    background: url('../../../../public/images/tidalpool.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-coconuttree {
    background: url('../../../../public/images/coconuttree.png') no-repeat
      center center fixed;
  }
  &.lesson-bg-tikisnack {
    background: url('../../../../public/images/tikisnack.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-beachblanket {
    background: url('../../../../public/images/beachblanket.png') no-repeat
      center center fixed;
  }
  &.lesson-bg-clammusic {
    background: url('../../../../public/images/clammusic.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-beachsurfboard {
    background: url('../../../../public/images/beachsurfboard.png') no-repeat
      center center fixed;
  }
  &.lesson-bg-urbangarden {
    background: url('../../../../public/images/urbanGarden.png') no-repeat
      center center fixed;
  }
  &.lesson-bg-manholecover {
    background: url('../../../../public/images/manHole.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-basketballcourt {
    background: url('../../../../public/images/basketBall.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-communitypantry {
    background: url('../../../../public/images/communitypantry.png') no-repeat center
    center fixed; 
  }
  &.lesson-bg-scarecrowin {
    background: url('../../../../public/images/scarecrow.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-tractorpull {
    background: url('../../../../public/images/tractor.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-rodeo {
    background: url('../../../../public/images/rodeo.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-relaxedaliens {
    background: url('../../../../public/images/relaxedaliens.png') no-repeat
      center center fixed;
  }
  &.lesson-bg-underwatercity {
    background: url('../../../../public/images/relaxedaliens.png') no-repeat center
      center fixed;
  }


  &.lesson-bg-insidevolcano {
    background: url('../../../../public/images/insidevolcano.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-outerspace {
    background: url('../../../../public/images/outerspace.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-messagein {
    background: url('../../../../public/images/messagein.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-cosmicdance {
    background: url('../../../../public/images/cosmicdance.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-underwatercity {
    background: url('../../../../public/images/underwatercity.png') no-repeat center
      center fixed;
  }
  &.lesson-bg-flyingcarpet {
    background: url('../../../../public/images/flyingcarpet.png') no-repeat center
      center fixed;
  }
}

.student-common-lesson {
  .student-lesson-container {
    align-items: center;
    display: flex;
    height: 100%;
    .play-buttons {
      padding: 0px 34px;
      position: relative;
      margin: auto;
      bottom: 0;
      @media only screen and (max-width: 950px) {
        padding: 0 0 0 96px;
      }
      .even {
        position: relative;
        .lesson-path-line {
          &::after {
            content: '';
            color: #fff;
            font-size: 20px;
            font-weight: 900;
            background: url('../../../../public/images/path.png') no-repeat;
            width: 30vw;
            height: 25vh;
            -webkit-background-size: 100% 100vh;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: contain;
            position: relative;
            display: block;
          }
        }
        .lesson-btn-pebble {
          position: relative;
          top: 21%;
          z-index: 9;
          @media only screen and (max-width: 1400px) {
            top: 18%;
          }
          @media only screen and (max-width: 1024px) {
            top: 15%;
          }
          @media only screen and (max-width: 950px) {
            top: 16%;
            left: -21%;
          }
        }
        &.lesson-stone {
          &:last-child {
            .play-button {
              // margin-left: 7%;
              @media only screen and (max-width: 950px) {
                margin-left: 8%;
              }
            }
            .lesson-path-line {
              @media only screen and (max-width: 950px) {
                width: 30vw;
              }
              // &::after {
              //   content: none;
              // }
            }
          }
          .lesson-name {
            font-size: 15px;
            width: 50%;
            text-align: center;
            transform: translate(-21%, 0);
            position: absolute;
            bottom: 80%;
            @media only screen and (max-width: 1200px) {
              font-size: 12px;
              transform: translate(-18%, 0);
              bottom: 80%;
            }
            @media only screen and (max-width: 1024px) {
              bottom: 84%;
            }
            @media only screen and (max-width: 950px) {
              transform: translate(-61%, 0);
              bottom: 75%;
            }
          }
        }
      }
      .odd {
        position: relative;
        .lesson-path-line {
          &::after {
            content: '';
            color: #fff;
            font-size: 20px;
            font-weight: 900;
            background: url('../../../../public/images/path2.png') no-repeat;
            width: 30vw;
            height: 25vh;
            -webkit-background-size: 100% 100vh;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: contain;
            position: relative;
            display: block;
          }
        }
        .lesson-btn-pebble {
          position: relative;
          top: 79%;
          z-index: 9;
          left: -16%;
          @media only screen and (max-width: 1400px) {
            top: 57%;
          }
          @media only screen and (max-width: 1360px) {
            top: 77%;
          }
          @media only screen and (max-width: 1200px) {
            top: 62%;
          }
          @media only screen and (max-width: 1024px) {
            top: 56%;
          }
          @media only screen and (max-width: 950px) {
            top: 76%;
            left: -21%;
          }
        }
        &.lesson-stone {
          &:last-child {
            .play-button {
              margin-left: 7%;
              @media only screen and (max-width: 950px) {
                margin-left: 8%;
              }
            }
            .lesson-path-line {
              @media only screen and (max-width: 950px) {
                width: 30vw;
              }
              // &::after {
              //   content: none;
              // }
            }
          }
          .lesson-name {
            font-size: 15px;
            width: 50%;
            text-align: center;
            transform: translate(-50%, 0);
            bottom: 23%;
            position: absolute;
            @media only screen and (max-width: 1400px) {
              bottom: 44%;
            }
            @media only screen and (max-width: 1360px) {
              bottom: 25%;
            }
            @media only screen and (max-width: 1200px) {
              font-size: 12px;
              transform: translate(-48%, 0);
              bottom: 36%;
            }
            @media only screen and (max-width: 1024px) {
              bottom: 43%;
            }
            @media only screen and (max-width: 950px) {
              transform: translate(-61%, 0);
              bottom: 22%;
            }
          }
        }
      }
      .star-progress-btn-outer .button-count-wrapper {
        width: max-content;
      }
      .play-button {
        bottom: 0;
        margin: auto;
        height: 9vh;
        margin-left: 9%;
        position: absolute;
        z-index: 1;
        top: -7px;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 1400px) {
          height: 6vh;
          margin-left: 6%;
        }
        @media only screen and (max-width: 1360px) {
          height: 8vh;
          margin-left: 10%;
        }
        @media only screen and (max-width: 1200px) {
          margin-left: 9%;
        }
        @media only screen and (max-width: 950px) {
          height: 12vh;
          margin-left: 8%;
        }
        // @media only screen and (max-width: 850px) {
        //   height: 12vh;
        // }
        img {
          height: 2rem;
          // margin-top: 0.5rem;
          @media only screen and (max-width: 950px) {
            height: 70%;
          }
        }
      }
      .lesson-path-pebble {
        position: relative;
        img {
          margin-left: 1%;
          width: 28%;
          @media only screen and (max-width: 1399px) {
            margin-left: -1%;
          }
          @media only screen and (max-width: 1100px) {
            margin-left: 2%;
          }
          @media only screen and (max-width: 950px) {
            width: 28%;
            margin-left: 0%;
          }
        }
      }
    }
  }
}

@media screen and (orientation: portrait) {
  .student-lesson-wrapper {
    background: url('../../../../public/images/rotate-screen.jpg') no-repeat
      center center fixed !important;
    height: 100vh;
    background-color: #000 !important;
    .student-lesson-container {
      display: none;
    }
  }
}
