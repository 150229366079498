.view-lesson-contents-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
}

.view-lessons-contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.content-type-icon {
  width: 36px;
  height: 36px;
}

.content-type-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
}

.content-type-desc {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
}

.article-type-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;

  .article-header-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 21.78px;
    color: #374151;
  }
}

.student-article-content {
  width: 100%;
  border-radius: 24px;
  border: 4px solid transparent;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .article-img {
    width: 100%;
    height: 230px;
    border-radius: 14px;
    object-fit: cover;
  }

  .article-content-details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .render-article-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #1f2937;
    }
  }
}

.document-type-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e5e7eb;
  border-radius: 600px;
  padding: 12px 16px 12px 24px;
}

.inner-doc-container {
  background: linear-gradient(178deg, #e5e7eb 10%, #b5b8bc 100%);
  &:hover {
    background: linear-gradient(180deg, #caddfb 10%, #caddfb 100%);
  }
}

.outer-doc-container {
  background: linear-gradient(
    180deg,
    #eeeeee 0%,
    #d8d5d5 50%,
    rgb(181 184 188 / 74%) 95%,
    rgb(181 184 188 / 67%) 100%
  );
  &:not(.outer-article-container):hover {
    background: linear-gradient(
      180deg,
      #caddfb 0%,
      #caddfb91 50%,
      #caddfbb8 95%,
      #caddfb 100%
    );
  }
}

.left-group {
  display: flex;
  align-items: center;

  .file-icon {
    width: 32px;
    height: 32px;
  }
  .document-file-name {
    font-size: 16px;
    line-height: 20px;
    color: #1f2937;
    font-weight: 600;
    margin-left: 8px;
  }
}

.action-icon {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.content-video-container {
  margin: 0 auto;
}

.content-video {
  width: auto;
  border-radius: 14px;
  object-fit: contain;
  height: 100%;
  max-height: 30rem;
  min-width: 50rem;
}

.content-img {
  width: 100%;
  border-radius: 14px;
  object-fit: cover;
  height: 400px;
}

.text-line-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}

.text-and-line-container {
  color: #111827;

  .editor-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #111827;
  }
}

.lesson-bookmark-icon {
  cursor: pointer;
  width: 48px;
  height: 48px;
}

.outer-article-container {
  .inner-doc-container {
    &:hover {
      background: linear-gradient(178deg, #e5e7eb 10%, #b5b8bc 100%);
    }
  }
}

.doc-view-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.docs-view-icon {
  cursor: pointer;
}

.doc-viewer-file-name {
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-shadow: 0px 1px 5px rgba(136, 13, 13, 0.2),
    0px 3px 4px rgba(123, 12, 12, 0.12), 0px 2px 4px rgba(91, 26, 26, 0.14);
}

.teacherCounsellor-viewContent-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  margin: 27px 20px;
  .contents-label {
    font-size: 22px;
    font-weight: 600;
    color: #101828;
  }
}

.text-to-speech-button {
  background: linear-gradient(#ffcc00, #ff9900) padding-box, linear-gradient(#fbfbfb, #050505) border-box;
  border: 2px solid transparent;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 22px;
  font-weight: bolder;
  transition: background 0.3s ease;
  position: relative;
  bottom: 2px;
  padding: 0;
  div {
    border: 1.5px solid transparent;
    border-radius: 50%;
    background: linear-gradient(#ffab0b, #ffab0b) padding-box, linear-gradient(#ffcc23, #ff7902) border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    span {
      position: relative;
      bottom: 1px;
      img {
        width: 24px;
      }
    }
  }
}

.voice-select {
  width: 12rem;
}
