.studentDashboard-container {
  display: flex;
  flex-direction: column;
  margin: 27px 20px;

  .sectionTitle {
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    color: #1f2937;
    margin-bottom: 24px;
  }

  .basicDetailsContainer {
    padding: 0px 24px;
    margin: 20px 0px;
  }

  .bottomSectionContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 44px;
    padding: 0px 24px;
  }
}

.profileDetailsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;

  .studentAvatarNameContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .studentAvatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      border: 1.5px solid #e5e7eb;
    }

    .nameUsernameWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .nameContainer {
        font-weight: 700;
        font-size: 24px;
        line-height: 20px;
        color: #374151;
      }

      .usernameContainer {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #111827;
      }
    }
  }
}

.bottomleftColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bottomRightColumn {
  flex: 1;

  .statsRow {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

.studentDashboardCardView {
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  align-items: center;
  gap: 16px;
  flex: 1;

  .iconWrapper {
    width: 64px;
    height: 64px;
  }

  .studentDashboardCardView-detailsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .headingTitleWrapper {
      font-weight: 700;
      font-size: 18px;
      line-height: 21.78px;
      color: #111827;
    }

    .valueWrapper {
      font-weight: 700;
      font-size: 24px;
      line-height: 20px;
      color: #1f2937;
    }
  }
}

@media (max-width: 768px) {
  .studentDashboard-container {
    .bottomSectionContainer {
      flex-direction: column;
      padding: 0;
      gap: 20px;
    }
  }

  .profileDetailsContainer {
    flex-direction: column;
    padding: 0;

    .studentAvatarNameContainer {
      flex-direction: column;
      align-items: center;
    }
  }

  .bottomleftColumn,
  .bottomRightColumn {
    width: 100%;
  }

  .studentDashboardCardView {
    width: 100%;
    align-items: center;
  }
}
