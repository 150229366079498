.download-attachment-link {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    svg {
        margin-right: 5px;
    }
}