.addQuesView-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .goBack-addQues {
    align-self: flex-start;
    cursor: pointer;
  }

  .addQuesAns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .quesTitle-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .quesTitleField {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #1f2937;
      }

      .quesWordLimit {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #374151;
      }
    }
  }
}

.fullWidthQues {
  width: 100%;

  .addQues-input {
    width: 100%;
    border: none;
    background: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #1f2937;
  }
}

.outer-addQues-container {
  background: linear-gradient(
    180deg,
    #eeeeee 0%,
    #d8d5d5de 50%,
    rgb(181 184 188 / 50%) 95%,
    rgba(181, 184, 188, 0.55) 100%
  );
}

.inner-addQues-container {
  background: linear-gradient(178deg, #e5e7eb26 10%, #e5e7eb 100%);
  padding: 7px;
}

.submitQues-wrapper {
  align-self: flex-end;
  .greyInner,
  .blueInner {
    padding: 4.35px 18.5px;
  }
}
