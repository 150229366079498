.dashboard-wrapper {
  img {
    width: 48px;
    height: 48px;
  }
}
.student_menu_modal {
  .transparent-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 50vh;
    .flex-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .css-10h4ql0-MuiModal-root-MuiDialog-root .MuiDialog-paper {
    max-width: 100% !important;
    padding: 0px !important;
  }
  .close-icon {
    display: none;
  }
}
